@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'League Spartan Black';
  src: url('./assets/fonts/League_Spartan/static/LeagueSpartan-Black.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'League Spartan Bold';
  src: url('./assets/fonts/League_Spartan/static/LeagueSpartan-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'League Spartan Extra Bold';
  src: url('./assets/fonts/League_Spartan/static/LeagueSpartan-ExtraBold.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'League Spartan Light';
  src: url('./assets/fonts/League_Spartan/static/LeagueSpartan-Light.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'League Spartan Regular';
  src: url('./assets/fonts/League_Spartan/static/LeagueSpartan-Regular.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}

.league-spartan-black{
  font-family: 'League Spartan Black';
}

.league-spartan-bold{
  font-family: 'League Spartan Bold';
}

.league-spartan-extra-bold{
  font-family: 'League Spartan Extra Bold';
}

.league-spartan-light{
  font-family: 'League Spartan Light';
}

body {
  margin: 0;
  font-family: 'League Spartan Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader-icon {
  display: inline-block;
  height: 6.4rem;
  width: 6.4rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: #ff9b00;
  position: relative;
  border-radius: 50%;
  margin-bottom: 2.5rem;
  -webkit-animation: rotate 0.3s linear infinite;
  animation: rotate 0.3s linear infinite;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: inherit !important;
  padding: 8px;
}
.ql-container.ql-snow {
  border: none !important;
}

.css-1fdsijx-ValueContainer {
  height: 45px;
}

@media (max-width: 640px) {
  .stepper .steps {
    flex-direction: column;
  }
  .stepper .steps .step {
    margin-bottom: 1rem;
  }
}
